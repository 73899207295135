import {
  EventModel,
  ExecutionMessage,
  ExecutionMessageModel,
  ShiftModel,
} from '@cuidador/database';
import { Typography } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import RightIcon from '@material-ui/icons/ChevronRight';
import { sortBy } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import CareCategoryList from '../../../components/CareCategoriesList';
import ExecutionMessageCard from '../../../components/ExecutionMessageCard';
import ExpelCaregiverButton from '../../../components/ExpelCaregiverButton';
import ExpelCaregiverDialog from '../../../components/ExpelCaregiverDialog';
import ShiftEventList from '../../../components/ShiftEventList';
import { formatEvents } from '../../../components/ShiftEventList/LastShiftCards/ScheduledMeasurementCard/utils';
import { separateComplicationsFromActivities } from '../../../components/ShiftEventList/utils';
import ShiftInProgressIndicator from '../../../components/ShiftsHistory/ShiftCard/ShiftInProgressIndicator';
import {
  getShiftExecutionRealTime,
  isAnnulledShiftExecution,
  isShiftExecutionWithContestation,
} from '../../../components/ShiftsHistory/utils';
import { AuthContext } from '../../../contexts/auth';
import useCanAccess from '../../../hooks/useCanAccess';
import useCaregiver from '../../../hooks/useCaregiver';
import useShift from '../../../hooks/useShift';
import { resolveErrorMessage } from '../../../utils/error';
import { TypographyWithIcon } from '../History/Shift/styles';
import { Backdrop, BackdropCircularProgress } from '../styles';
import {
  Container,
  ExecutionMessageContainer,
  IconContainer,
  StyledTypographyAdditionalCoverage,
  StyledTypographyTitle,
  SubTitleContainer,
  TitleContainer,
  TitleHeaderContainer,
} from './styles';
import { ReactComponent as IconCheck } from '../../../assets/Icon-check-circle.svg';
import { StyledButton } from './styles';

const Shift: React.FC = () => {
  const { userInfo } = useContext(AuthContext);
  const patientId = userInfo?.activePatientId;
  const [lastShift, setLastShift] = useState<ShiftModel>();
  const [lastShiftEvents, setLastShiftEvents] = useState<EventModel[]>([]);
  const [lastShiftMessages, setLastShiftMessages] = useState<
    ExecutionMessageModel[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [isExpelDialogOpen, setIsExpelDialogOpen] = useState(false);
  const { expelCaregiver, getLastShiftByPatientId } = useShift();
  const { getById, byId } = useCaregiver();
  const isCaregiverAppAccess = userInfo?.user?.role?.caregiverAppAccess;

  const { isAllowedToInvoke: isAllowedToExpel } = useCanAccess(
    'care/shift.expel'
  );

  const handleCareRegisterButton = () => {
    const prod = process.env.REACT_APP_ENV === 'production';
    let url = ``;
    if (!prod) {
      url = `https://qa-caregiver.cuidadordeconfianca.com.br/agenda-de-plantoes/${patientId}`;      
    } else {
      url = `https://app.cuidadordeconfianca.com.br/agenda-de-plantoes/${patientId}`;
    }
    
    window.location.href = url;
  };

  async function loadPageData() {
    if (!patientId) return;
    try {
      setLoading(true);
      const lastShiftPatient = await getLastShiftByPatientId(patientId);
      if (lastShiftPatient) {
        setLastShift(lastShiftPatient);
        setLastShiftEvents((lastShiftPatient.events as EventModel[]) || []);
        setLastShiftMessages(
          lastShiftPatient.messages as ExecutionMessageModel[]
        );
      }
    } catch (err) {
      /**
       * If shift not found do not show toast.
       * This means that there is no previous shift
       */
      if (err?.response?.status !== 404) {
        toast.error(resolveErrorMessage(err));
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadPageData();
  }, []);

  useEffect(() => {
    if (!lastShift?.executionInProgress?.caregiverId) return;
    getById(lastShift.executionInProgress.caregiverId);
  }, [lastShift]);

  const {
    complicationEvents,
    eventsWithoutComplications,
  } = separateComplicationsFromActivities(lastShiftEvents);

  const formattedEventsWithoutComplications = useMemo(
    () => formatEvents(eventsWithoutComplications) ?? [],
    [lastShiftEvents]
  );

  const handleExpelCaregiver = async (option: 'keep' | 'erase') => {
    setIsExpelDialogOpen(false);
    if (!lastShift) {
      toast.error('Não foi possível remover usuário do plantão.');
      return;
    }

    try {
      await expelCaregiver(lastShift.id!, option !== 'erase');
      toast.success('Usuário removido do plantão com sucesso');
    } catch {
      toast.error('Não foi possível remover usuário do plantão.');
    } finally {
      loadPageData();
    }
  };

  if (loading)
    return (
      <Backdrop open={loading}>
        {loading && (
          <BackdropCircularProgress data-testid="table-backdrop-spinner" />
        )}
      </Backdrop>
    );

  return (
    <>
      <Container>
        {lastShift?.status === 'in_progress' && (
          <TitleContainer>
            <TitleHeaderContainer>
              <StyledTypographyTitle variant="h6">
                {lastShift.shiftSchedule?.name ?? lastShift.name ?? ''}
              </StyledTypographyTitle>
              {lastShift?.executionInProgress?.caregiverId && isAllowedToExpel && (
                <>
                  <TitleHeaderContainer>
                    {Boolean(isCaregiverAppAccess) && (
                      <StyledButton onClick={handleCareRegisterButton}>
                        <IconCheck />            
                        &nbsp;Registrar cuidados&nbsp;
                      </StyledButton>                          
                    )}
                    <ExpelCaregiverButton
                      onClick={() => setIsExpelDialogOpen(true)}
                      data-testid={`expel-button-${lastShift.id}`}
                    />
                    <ExpelCaregiverDialog
                      isOpen={isExpelDialogOpen}
                      onClose={() => setIsExpelDialogOpen(false)}
                      onConfirm={(option: 'keep' | 'erase') => {
                        handleExpelCaregiver(option);
                      }}
                      isLoading={false}
                      caregiver={byId[lastShift.executionInProgress.caregiverId]}
                    />
                  </ TitleHeaderContainer>                    
                </>
              )}
            </TitleHeaderContainer>

            <>
              {/* It's necessary to sort it in here because executions
              could be loaded by backend in any order.
              Also, the order by query string is already filled. */}
              {sortBy(
                lastShift.executions,
                (item) => item.startedAt ?? item.createdAt
              ).map((execution, index) => (
                <TypographyWithIcon
                  variant="subtitle1"
                  key={`execution-caregiver-${index}`}
                  data-testid={`execution-caregiver-${index}`}
                >
                  {isAnnulledShiftExecution(execution) ? (
                    <>
                      <del
                        data-testid={`test-execution-strikethrough-${index}`}
                      >
                        <b>{getShiftExecutionRealTime(execution)}</b>
                      </del>
                      {!!isShiftExecutionWithContestation(execution) && (
                        <AccessTimeIcon
                          data-testid={`test-execution-time-icon-${index}`}
                        />
                      )}
                      <div>|</div>
                      <del>{execution.caregiver?.user?.name}</del>
                    </>
                  ) : (
                    <>
                      <b>{getShiftExecutionRealTime(execution)}</b>
                      {!!isShiftExecutionWithContestation(execution) && (
                        <AccessTimeIcon
                          data-testid={`test-execution-time-icon-${index}`}
                        />
                      )}
                      <div>|</div>
                      {execution.caregiver?.user?.name}
                    </>
                  )}
                </TypographyWithIcon>
              ))}
            </>
            <ShiftInProgressIndicator shift={lastShift} />
            {!!lastShift?.isAdditionalCoverage && (
              <StyledTypographyAdditionalCoverage>
                *Essa é uma hora-extra / cobertura.
              </StyledTypographyAdditionalCoverage>
            )}
          </TitleContainer>
        )}

        {lastShift?.status !== 'in_progress' && (
          <TitleContainer>
            <Typography variant="h6">
              Não existe nenhum plantão em progresso no momento.
            </Typography>
            <br />  
            {Boolean(isCaregiverAppAccess) && (
              <StyledButton onClick={handleCareRegisterButton}>
                <IconCheck />            
                &nbsp;Registrar cuidados&nbsp;
              </StyledButton>                          
            )}
          </TitleContainer>                     
        )}

        {lastShift?.status === 'in_progress' && (
          <>
            {lastShiftEvents?.length > 0 ? (
              <>
                {!!complicationEvents && complicationEvents.length > 0 && (
                  <ShiftEventList
                    events={complicationEvents || []}
                    title="Intercorrências"
                  />
                )}
                {!!formattedEventsWithoutComplications &&
                formattedEventsWithoutComplications.length > 0 ? (
                  <ShiftEventList
                    events={formattedEventsWithoutComplications || []}
                    title="Atividades"
                  />
                ) : (
                  <TitleContainer>
                    <Typography variant="h6">
                      Nenhum evento registrado no plantão.
                    </Typography>
                  </TitleContainer>
                )}
              </>
            ) : (
              <TitleContainer>
                <Typography variant="h6">
                  Nenhum evento registrado no último plantão.
                </Typography>
              </TitleContainer>
            )}

            {lastShift?.careQuestionAnswers ? (
              <>
                <CareCategoryList shift={lastShift} />
              </>
            ) : (
              <TitleContainer>
                <Typography variant="h6">
                  Nenhuma pergunta de cuidado relacionada ao último plantão.
                </Typography>
              </TitleContainer>
            )}

            {lastShiftMessages && lastShiftMessages.length > 0 && (
              <>
                <ExecutionMessageContainer>
                  <SubTitleContainer>
                    <IconContainer>
                      <RightIcon />
                    </IconContainer>
                    <Typography variant="h6">
                      Anotações e evolução do plantão
                    </Typography>
                  </SubTitleContainer>
                  {lastShiftMessages.map((message, index) => (
                    <ExecutionMessageCard
                      executionMessage={message as ExecutionMessage}
                      key={`execution-message-card-${index}`}
                      data-testid={`execution-message-card-${index}`}
                    />
                  ))}
                </ExecutionMessageContainer>
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default Shift;
