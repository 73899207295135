import { FormControlLabel } from '@material-ui/core';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { toast } from 'react-toastify';
import { phoneMask } from '../../utils/inputs';
import {
  FormCardContainer,
  HelperContainer,
  HelperText,
  StyledBoldTitle,
  StyledFormikTextField as FormikTextField,
} from '../FormCardContainer';
import MedicBalloon from '../MedicBalloon';
import StyledButton from '../StyledButton';
import {
  BlackTypography,
  Centralizer,
  ContentContainer,
  Form,
  StyledCheckbox,
} from './styles';
import { FormValues, urlMask, validationSchema } from './utils';

const ContactsUpsertForm: React.FC<{
  initialValues: FormValues;
  innerRef?: React.Ref<FormikProps<FormValues>>;
  onSubmit: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => void | Promise<void>;
}> = ({ initialValues, innerRef, onSubmit }) => {
  const validateAndSubmit = (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    if (values.link || values.phoneNumber) {
      onSubmit(values, formikHelpers);
    } else {
      toast.error('Preencha o número de telefone ou link de contato');
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={validateAndSubmit}
        validateOnChange={false}
        innerRef={innerRef}
      >
        {({
          values,
          setFieldValue,
          isSubmitting,
          errors,
          touched,
          handleChange,
        }) => {
          return (
            <Form
              // noValidate disables browser default validation
              noValidate={true}
            >
              <MedicBalloon text="Cadastre aqui os contatos dos familiares e médicos(as) que devem ser acionados no caso de uma emergência." />
              <ContentContainer>
                <FormCardContainer>
                  <StyledBoldTitle variant="subtitle2">
                    Nome do contato
                  </StyledBoldTitle>

                  <FormikTextField
                    color="secondary"
                    id="name"
                    inputProps={{ 'data-testid': 'name', maxLength: 100 }}
                    placeholder="Nome"
                    name="name"
                    margin="normal"
                    autoComplete="off"
                    size="small"
                    helperText={
                      <HelperContainer>
                        <HelperText>Exemplo: Dr João, etc.</HelperText>
                        {touched.name && errors.name}
                      </HelperContainer>
                    }
                  />
                </FormCardContainer>

                <FormCardContainer>
                  <BlackTypography variant="subtitle2">
                    Adicione <b>pelo menos uma das duas opções</b> abaixo, ou,
                    se preferir, pode adicionar as duas.
                  </BlackTypography>

                  <FormikTextField
                    label="Telefone"
                    color="secondary"
                    id="phoneNumber"
                    inputProps={{ 'data-testid': 'phoneNumber' }}
                    placeholder="(99) 99999-9999"
                    name="phoneNumber"
                    margin="normal"
                    autoComplete="off"
                    type="tel"
                    size="small"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('phoneNumber', phoneMask(e.target.value));
                    }}
                  />
                  {!!values.phoneNumber && (
                    <FormControlLabel
                      control={
                        <StyledCheckbox
                          name="isWhatsapp"
                          id="isWhatsapp"
                          checked={values.isWhatsapp || false}
                          onChange={handleChange}
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          inputProps={{ 'data-testid': 'whatsapp' } as any}
                        />
                      }
                      label="Whatsapp"
                    />
                  )}

                  <FormikTextField
                    label="Link"
                    color="secondary"
                    id="link"
                    inputProps={{
                      'data-testid': 'link',
                      maxLength: 255,
                    }}
                    name="link"
                    placeholder="https://www.meucontato.com.br"
                    margin="normal"
                    autoComplete="off"
                    size="small"
                    value={urlMask(values.link)}
                    helperText={
                      <HelperContainer>
                        <HelperText>{touched.link && errors.link}</HelperText>
                      </HelperContainer>
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('link', urlMask(e.target.value));
                    }}
                  />
                </FormCardContainer>

                <FormCardContainer>
                  <StyledBoldTitle variant="subtitle2">
                    Em qual caso entrar em contato?
                  </StyledBoldTitle>

                  <FormikTextField
                    color="secondary"
                    id="callingCondition"
                    inputProps={{
                      'data-testid': 'callingCondition',
                      maxLength: 150,
                    }}
                    placeholder="Entrar em contato..."
                    name="callingCondition"
                    margin="normal"
                    autoComplete="off"
                    size="small"
                    helperText={
                      <HelperContainer>
                        <HelperText>
                          Exemplo: No caso de falta de remédio.
                        </HelperText>
                        {touched.callingCondition && errors.callingCondition}
                      </HelperContainer>
                    }
                  />

                  <StyledBoldTitle variant="subtitle2">
                    Instruções (opcional)
                  </StyledBoldTitle>

                  <FormikTextField
                    color="secondary"
                    id="relevantInformation"
                    inputProps={{
                      'data-testid': 'relevantInformation',
                      maxLength: 500,
                    }}
                    name="relevantInformation"
                    margin="normal"
                    autoComplete="off"
                    size="small"
                    multiline="true"
                    rows="5"
                  />
                </FormCardContainer>

                <Centralizer>
                  <StyledButton
                    data-testid="submit-contact"
                    type="submit"
                    size="large"
                    color="inherit"
                    disabled={isSubmitting || isEqual(initialValues, values)}
                  >
                    Salvar
                  </StyledButton>
                </Centralizer>
              </ContentContainer>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ContactsUpsertForm;
