import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import qs from 'query-string'
import { AxiosResponse } from 'axios'
import { PatientModel } from '@cuidador/database'
import { AddRounded, Today } from '@material-ui/icons'
import usePatient, { CustomStatus, GetPatientPaginatedRequestParams, PatientEventFilter, PatientQueryType } from '../../../hooks/usePatient'
import useCanAccess from '../../../hooks/useCanAccess'
import { AuthContext } from '../../../contexts/auth'
import { Item } from '../../../utils/store'
import { handleGetProfilePictureURL } from '../../Organization/utils'
import LoadingBackdrop from '../../../components/LoadingBackdrop'
import FilterChips from '../../Patient/PatientSelect/Patients/FilterChips'
import { Container, FormControl, Grid, MenuItem, Select, Typography, useTheme } from '@material-ui/core'
import { PatientSelectCard } from '../../../components/SelectCard/Patient'
import { getAge } from '../../Patient/PatientSelect/utils'
import { FabStyled } from '../../../components/FabStyled'
import { SearchTextField } from '../../../components/SearchTextField'
import MedicationStatusCard from '../../../components/MedicationStatus/MedicationStatusCard'
import Occurrences from '../../../assets/Occurrences.json'
import { endOfDay, startOfDay } from 'date-fns'
import axios from '../../../config/axios'
import { DatePicker } from '@material-ui/pickers'
import { resolveShiftStatusLabel } from '../../../components/SelectCard/utils'
import { PatientListOccurrences, getFilteredPatientList } from '../../../utils/patients/filteretPatientList'
import { Pagination } from '../../../components/Pagination'
import { EventDate, getFilteredEventsByOccurrences } from '../../../components/SelectCard/Patient/ExpandedContent/EvolutionList'
import { useAsync } from 'react-async-hook'

export function PatientsList () {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [patientListFilter, setPatientListFilter] = useState<string>('all')
  const [patients, setPatients] = useState<PatientModel[]>([])
  const [allPatients, setAllPatients] = useState<PatientModel[]>([])
  const [patientIdToProfilePictureURL, setPatientIdToProfilePictureURL] = useState<Record<number, string>>({})
  const [customStatus, setCustomStatus] = useState<CustomStatus[]>(['enabled', 'pending'])
  const [patientQueryType, setPatientQueryType] = useState<PatientQueryType | undefined>(undefined)
  const [isLoadingOccurrences, setIsLoadingOccurrences] = useState<boolean>(false)
  const [genericOccurrences, setGenericOccurrences] = useState<number[]>([0, 0, 0, 0, 0])
  const [fromDate, setFromDate] = useState(new Date())
  const [toDate, setToDate] = useState(new Date())
  const [shouldUpdate, setShouldUpdate] = useState(false)
  const [patientOccurrencesList, setPatientOccurrencesList] = useState<PatientListOccurrences>([])

  const history = useHistory()
  const theme = useTheme()
  const { getRelatedPatientsPaginated, getOrganizationPatientsPaginated, getProfilePicture, loading: loadingPatient, total, setActive } = usePatient()
  const { isAllowedToRead: isAllowedToReadProfilePicture } = useCanAccess('media/profile-picture')
  const { isAllowedToCreate: canCreatePatient, isAllowedToRead: isAllowedToReadRelatedPatient } = useCanAccess('user/patient')
  const { isAllowedToRead: isAllowedToReadOrganizationPatient } = useCanAccess('user/patient.by-organization')
  const { userInfo, refreshUserInfo, loading: loadingAuth } = useContext(AuthContext)

  const activePatient = userInfo?.activePatient
  const historyLocationSearch = history.location.search

  useEffect(() => {
    if (!isAllowedToReadRelatedPatient) {
      if (!isAllowedToReadOrganizationPatient) {
        toast.error('Você não tem permissão para visualizar essa página')
        history.push('/cadastros')
        return
      } else {
        // Forces query type to organization (which is enabled by isAllowedToReadOrganizationPatient)
        setPatientQueryType('organizationPatients')
      }
    } else {
      setPatientQueryType('relatedPatients')
    }
    makeFetchAllPatients(getRelatedPatientsPaginated)
  }, [])

  useEffect(() => {
    handleGetPatients()
    refreshUserInfo()
  }, [page, customStatus, patientQueryType, historyLocationSearch, patientListFilter, shouldUpdate])

  useEffect(() => {
    if (page !== 1) {
      setPage(1)
    } else {
      setShouldUpdate(!shouldUpdate)
    }
  }, [fromDate, toDate, pageSize])

  useEffect(() => {
    setPatientOccurrencesList([])
    setGenericOccurrences([0, 0, 0, 0, 0])
  }, [fromDate, toDate, allPatients, patients])

  useAsync(async () => {
    if (patients.length > 0) {
      setIsLoadingOccurrences(true)
      const patientListTotalOccurrences = [0, 0, 0, 0, 0]
      const patientTotalOccurrencesList: PatientListOccurrences = []
      await Promise.all(patients.map(async (patient) => {
        const fromDateString = fromDate
          ? 'updatedTimeHappensAt[min]=' + startOfDay(fromDate)?.toISOString()
          : ''
        const toDateString = toDate
          ? 'updatedTimeHappensAt[max]=' + endOfDay(toDate)?.toISOString()
          : ''
        const patientTotalOccurrences = [0, 0, 0, 0, 0]

        const patientEventDates: EventDate[] = (await axios.get(`${process.env.REACT_APP_GATEWAY_URL}/api/v1/report/event/history/${patient.id}?${fromDateString}&${toDateString}`))?.data
        for (let i = 0; i < patientEventDates.length; i++) {
          const patientOccurrences = getFilteredEventsByOccurrences(patientEventDates[i].events, fromDate, toDate).occurrences
          patientOccurrences.forEach((val, idx) => {
            patientTotalOccurrences[idx] += val
          })
        }

        patientTotalOccurrences.forEach((val, idx) => {
          patientListTotalOccurrences[idx] += Number(Boolean(val))
        })

        patientTotalOccurrencesList.push({ patientId: patient.id!, occurrencesOfPatient: patientTotalOccurrences})
      }))

      setPatientOccurrencesList(patientTotalOccurrencesList)
      console.log(patientOccurrencesList)
      setGenericOccurrences(patientListTotalOccurrences)
      setIsLoadingOccurrences(false)
    }
  }, [fromDate, toDate, patients])

  const isPatientQueryTypeToogleable = useMemo(() => {
    return isAllowedToReadOrganizationPatient && isAllowedToReadRelatedPatient
  }, [isAllowedToReadOrganizationPatient, isAllowedToReadRelatedPatient])

  const handleGetPatients = () => {
    if (patientQueryType === undefined) {
      // Not ready yet
      return
    }
    if (patientQueryType === 'relatedPatients') {
      return handleGetRelatedPatientsPaginated()
    } else {
      return handleGetPatientsOrganizationPaginated()
    }
  }

  const handleTogglePatientQueryType = () => {
    if (!isPatientQueryTypeToogleable) {
      return
    }
    setPatientQueryType((currentPatientQueryType) =>
      currentPatientQueryType === 'organizationPatients'
        ? 'relatedPatients'
        : 'organizationPatients',
    )
  }

  const makeFetchPatients = (
    fetchFn: (
      params: GetPatientPaginatedRequestParams
    ) => Promise<AxiosResponse<{
      results: Item<PatientModel>[]
      total: number
    }>>,
  ) => {
    const params = qs.parse(historyLocationSearch)
    fetchFn({ ...params, customStatus, page: 0, orderBy: 'name', limit: 999999 })
      .then(async (response) => {
        const patientsToInsert = response.data.results
        for (const patient of patientsToInsert) {
          if (!patient.id || !isAllowedToReadProfilePicture) return
          handleGetProfilePictureURL(patient.id, getProfilePicture).then(
            (url) => {
              if (!url) return
              setPatientIdToProfilePictureURL((previousMap) => {
                if (!patient.id) return { ...previousMap }
                const newMap = { ...previousMap }
                newMap[patient.id] = url
                return newMap
              })
            },
          )
        }

        const fullList = getFilteredPatientList(patientsToInsert, patientOccurrencesList, patientListFilter)
        setPatients(fullList)
      })
      .catch((e) => {
        toast.error(e)
      })
  }

  const makeFetchAllPatients = (
    fetchFn: (
      params: GetPatientPaginatedRequestParams
    ) => Promise<AxiosResponse<{
      results: Item<PatientModel>[]
      total: number
    }>>,
  ) => {
    const params = qs.parse(historyLocationSearch)
    fetchFn({ ...params, customStatus, orderBy: 'name', limit: pageSize, page: page - 1 })
      .then((response) => {
        const patientsToInsert = response.data.results
        for (const patient of patientsToInsert) {
          if (!patient.id || !isAllowedToReadProfilePicture) return
          handleGetProfilePictureURL(patient.id, getProfilePicture).then(
            (url) => {
              if (!url) return
              setPatientIdToProfilePictureURL((previousMap) => {
                if (!patient.id) return { ...previousMap }
                const newMap = { ...previousMap }
                newMap[patient.id] = url
                return newMap
              })
            },
          )
        }
        setAllPatients(patientsToInsert)
      })
      .catch((e) => {
        toast.error(e)
      })
  }

  const handleGetPatientsOrganizationPaginated = () => {
    makeFetchPatients(getOrganizationPatientsPaginated)
  }

  const handleGetRelatedPatientsPaginated = () => {
    makeFetchPatients(getRelatedPatientsPaginated)
  }

  const onChangeParams = () => {
    if (page !== 1) {
      setPage(1)
    }
  }

  const handleEventFilterChange = (value?: string) => {
    if (value) {
      value === patientListFilter ? setPatientListFilter('all') : setPatientListFilter(value)
    }
    setPage(1)
  }

  const isLoading =
    loadingAuth || loadingPatient || patientQueryType === undefined

  if (isLoading && patients.length === 0) {
    return <LoadingBackdrop loading={isLoading} />
  }

  const selectPatient = async (id: number) => {
    try {
      await setActive(Number(id))
      await refreshUserInfo()
      history.push('/acompanhar/plantao')
    } catch (err) {
      toast.error(err)
    }
  }

  const generateRandomKey = (length:number) => {
    const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  };

  return (
    <Container maxWidth='md'>
      <div style={{
        width: '100%',
        display: 'flex',
      }}>
        <SearchTextField
          fieldName='patient-query'
          onChangeDebounced={onChangeParams}
        />
      </div>
      <FilterChips
        setCustomStatus={setCustomStatus}
        customStatus={customStatus}
        isPatientQueryTypeToogleable={isPatientQueryTypeToogleable}
        handleTogglePatientQueryType={handleTogglePatientQueryType}
        patientQueryType={patientQueryType}
      />
      <div style={{
        overflowX: 'auto',
        width: '100%',
        maxWidth: '800px',
        padding: theme.spacing(1),
        boxSizing: 'border-box',
        display: 'flex',
        gap: theme.spacing(1),
        margin: 'auto',
        justifyContent: 'start',
      }}>
        {Occurrences.map((item, index) =>
          index !== 3
            ? (
              <MedicationStatusCard
                key={index}
                icon={item.icon}
                title={item.title}
                color={item.backgroundColor}
                value={genericOccurrences[index]}
                isLoading={isLoadingOccurrences}
                filterType={PatientEventFilter[index] as unknown as PatientEventFilter}
                onFilter={handleEventFilterChange}
                selected={patientListFilter === item.code}
              />
            )
            : (
              <MedicationStatusCard
                key={index}
                icon={item.icon}
                title={item.title}
                color={item.backgroundColor}
                value={patients.filter(patient => resolveShiftStatusLabel(patient.shifts) === 'Atrasado' && patient.status === 'enabled').length}
                isLoading={isLoadingOccurrences}
                filterType={PatientEventFilter[index] as unknown as PatientEventFilter}
                onFilter={handleEventFilterChange}
                selected={patientListFilter === item.code}
              />

            ))}
      </div>

      <Container style={{ padding: 0 }}>
        <div
          data-testid='card-container'
          style={{
            marginBottom: theme.spacing(3),
            width: '100%',
            maxWidth: '800px',
            margin: 'auto',
          }}
        >
          <Grid container style={{ padding: '0 24px' }}>
            <Grid item xs={12}>
              <Typography style={{ fontSize: '18px', color: '#212121' }}>Período</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography
                style={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#616161',
                  fontSize: '12px',
                }}
                align='center'
              >
                De
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <DatePicker
                disabled={isLoadingOccurrences}
                // disabled
                placeholder='dd/mm/aaaa'
                format='dd/MM/yyyy'
                margin='dense'
                value={startOfDay(fromDate)}
                maxDate={endOfDay(toDate)}
                onChange={value => value && setFromDate(value)}
              />
            </Grid>
            <Grid item xs={1}>
              <Typography
                style={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#616161',
                  fontSize: '12px',
                }}
                align='center'
              >
                Até
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <DatePicker
                disabled={isLoadingOccurrences}
                // disabled
                placeholder='dd/mm/aaaa'
                format='dd/MM/yyyy'
                margin='dense'
                value={endOfDay(toDate)}
                minDate={(startOfDay(fromDate))}
                onChange={value => value && setToDate(value)}
              />
            </Grid>
          </Grid>
          {activePatient && patientListFilter === 'all' && (
            <PatientSelectCard
              key={Number(activePatient.id)}
              id={Number(activePatient.id)}
              name={activePatient.name || ''}
              age={getAge(activePatient.dateOfBirth)}
              profilePictureURL={
                patientIdToProfilePictureURL[Number(activePatient.id)]
              }
              status={String(activePatient.status)}
              isNew={Number(activePatient.isNew)}
              shifts={activePatient.shifts}
              active
              handleClick={() => selectPatient(activePatient.id!)}
              loadingOccurrences={isLoadingOccurrences}
              fromDate={fromDate}
              toDate={toDate}
            />
          )}
          {patients.filter((_, idx) => (idx < (pageSize * page)) && (idx + 1 > (pageSize * (page - 1)))).map((patient) => {
            if (patient.id === activePatient?.id && patientListFilter === 'all') return null
            return (
              <PatientSelectCard
                key={Number(patient.id)}
                id={Number(patient.id)}
                name={patient.name || ''}
                age={getAge(patient.dateOfBirth)}
                profilePictureURL={
                  patientIdToProfilePictureURL[Number(patient.id)]
                }
                status={String(patient.status)}
                isNew={Number(patient.isNew)}
                shifts={patient.shifts}
                handleClick={() => selectPatient(patient.id!)}
                loadingOccurrences={isLoadingOccurrences}
                fromDate={fromDate}
                toDate={toDate}
              />
            )
          })}
        </div>
        {(loadingAuth || loadingPatient) && (
          <LoadingBackdrop loading={loadingAuth || loadingPatient} />
        )}
        <div
          style={{ display: 'flex', justifyContent: 'center', marginBlock: '16px' }}
        >
          <Pagination
            count={Math.ceil(patients.length / pageSize)}
            page={page}
            onChange={(p) => setPage(p)}
          />
          <FormControl>
            <Select
              value={pageSize}
              onChange={ev => {
                setPageSize(Number(ev.target.value))
                setPage(1)
              }}
            >
              <MenuItem value={10}>10 por página</MenuItem>
              <MenuItem value={20}>25 por página</MenuItem>
              <MenuItem value={30}>100 por página</MenuItem>
            </Select>
          </FormControl>
        </div>
      </Container>
      {
        canCreatePatient && (
          <>
            <FabStyled
              onClick={() => {
                const randomKeyBefore = generateRandomKey(13); // Gera 13 caracteres antes
                const randomKeyAfter = generateRandomKey(19);  // Gera 19 caracteres depois
                const prod = process.env.REACT_APP_ENV === 'production';
                let url = ``; 
                if (!prod) {
                  url = `https://qa-schedule.cuidadordeconfianca.com.br/?key=${randomKeyBefore}${userInfo?.id}${randomKeyAfter}`;
                } else {
                  url = `https://schedule.cuidadordeconfianca.com.br/?key=${randomKeyBefore}${userInfo?.id}${randomKeyAfter}`;
                }                
                window.open(url, '_blank');
              }}
              color={theme.palette.secondary.main}
              backgroundColor={theme.palette.background.default}
              borderColor={theme.palette.secondary.main}
              bottom={80}
            >
              <Today />
            </FabStyled>

            <FabStyled
              onClick={() => history.push('/cadastro-psc')}
              color={theme.palette.secondary.main}
              backgroundColor={theme.palette.background.default}
              borderColor={theme.palette.secondary.main}
            >
              <AddRounded />
            </FabStyled>
          </>
        )
      }
    </Container>
  )
}
